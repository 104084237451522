import React from 'react';
import {
	Button,
	Checkbox,
	Form,
	Icon,
	Input,
	message,
	Dropdown,
	DatePicker,
	Menu,
	Upload,
	Select,
	Col,
	Layout,
	Row
} from 'antd';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import { auth, database, storage } from '../firebase/firebase';
import HorizontalDefault from "./Topbar/HorizontalDefault/index";
import Moment from "moment";
import { version } from "util/config";

import { connect } from 'react-redux';
import {
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignUp,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';

import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';


const FormItem = Form.Item;
const Option = Select.Option;
const codes = [
	"NORUTEC", "NORUTEC001",
"NORUTEC1",
"NORUTEC1",
"NORUTEC2",
"NORUTEC3",
"NORUTEC4",
"NORUTEC5",
"NORUTEC6",
"NORUTEC7",
"NORUTEC8",
"NORUTEC9",
"NORUTEC10",
	"NORUTEC002",
	"NORUTEC003",
	"NORUTEC004",
	"NORUTEC005",
	"NORUTEC006",
	"NORUTEC007",
	"NORUTEC008",
	"NORUTEC009",
	"NORUTEC010",
	"NORUTEC011",
	"NORUTEC012",
	"NORUTEC013",
	"NORUTEC014",
	"NORUTEC015",
	"NORUTEC016",
	"NORUTEC017",
	"NORUTEC018",
	"NORUTEC019",
	"NORUTEC020",
	"NORUTEC021",
	"NORUTEC022",
	"NORUTEC023",
	"NORUTEC024",
	"NORUTEC025",
	"NORUTEC026",
	"NORUTEC027",
	"NORUTEC028",
	"NORUTEC029",
	"NORUTEC030",
	"NORUTEC031",
	"NORUTEC032",
	"NORUTEC033",
	"NORUTEC034",
	"NORUTEC035",
	"NORUTEC036",
	"NORUTEC037",
	"NORUTEC038",
	"NORUTEC039",
	"NORUTEC040",
	"NORUTEC041",
	"NORUTEC042",
	"NORUTEC043",
	"NORUTEC044",
	"NORUTEC045",
	"NORUTEC046",
	"NORUTEC047",
	"NORUTEC048",
	"NORUTEC049",
	"NORUTEC050",
	"NORUTEC051",
	"NORUTEC052",
	"NORUTEC053",
	"NORUTEC054",
	"NORUTEC055",
	"NORUTEC056",
	"NORUTEC057",
	"NORUTEC058",
	"NORUTEC059",
	"NORUTEC060",
	"NORUTEC061",
	"NORUTEC062",
	"NORUTEC063",
	"NORUTEC064",
	"NORUTEC065",
	"NORUTEC066",
	"NORUTEC067",
	"NORUTEC068",
	"NORUTEC069",
	"NORUTEC070",
	"NORUTEC071",
	"NORUTEC072",
	"NORUTEC073",
	"NORUTEC074",
	"NORUTEC075",
	"NORUTEC076",
	"NORUTEC077",
	"NORUTEC078",
	"NORUTEC079",
	"NORUTEC080",
	"NORUTEC081",
	"NORUTEC082",
	"NORUTEC083",
	"NORUTEC084",
	"NORUTEC085",
	"NORUTEC086",
	"NORUTEC087",
	"NORUTEC088",
	"NORUTEC089",
	"NORUTEC090",
	"NORUTEC091",
	"NORUTEC092",
	"NORUTEC093",
	"NORUTEC094",
	"NORUTEC095",
	"NORUTEC096",
	"NORUTEC097",
	"NORUTEC098",
	"NORUTEC099",
	"NORUTEC100",
	"NORUTEC101",
	"NORUTEC102",
	"NORUTEC103",
	"NORUTEC104",
	"NORUTEC105",
	"NORUTEC106",
	"NORUTEC107",
	"NORUTEC108",
	"NORUTEC109",
	"NORUTEC110",
	"NORUTEC111",
	"NORUTEC112",
	"NORUTEC113",
	"NORUTEC114",
	"NORUTEC115",
	"NORUTEC116",
	"NORUTEC117",
	"NORUTEC118",
	"NORUTEC119",
	"NORUTEC120",
	"NORUTEC121",
	"NORUTEC122",
	"NORUTEC123",
	"NORUTEC124",
	"NORUTEC125",
	"NORUTEC126",
	"NORUTEC127",
	"NORUTEC128",
	"NORUTEC129",
	"NORUTEC130",
	"NORUTEC131",
	"NORUTEC132",
	"NORUTEC133",
	"NORUTEC134",
	"NORUTEC135",
	"NORUTEC136",
	"NORUTEC137",
	"NORUTEC138",
	"NORUTEC139",
	"NORUTEC140",
	"NORUTEC141",
	"NORUTEC142",
	"NORUTEC143",
	"NORUTEC144",
	"NORUTEC145",
	"NORUTEC146",
	"NORUTEC147",
	"NORUTEC148",
	"NORUTEC149",
	"NORUTEC150",
	"NORUTEC151",
	"NORUTEC152",
	"NORUTEC153",
	"NORUTEC154",
	"NORUTEC155",
	"NORUTEC156",
	"NORUTEC157",
	"NORUTEC158",
	"NORUTEC159",
	"NORUTEC160",
	"NORUTEC161",
	"NORUTEC162",
	"NORUTEC163",
	"NORUTEC164",
	"NORUTEC165",
	"NORUTEC166",
	"NORUTEC167",
	"NORUTEC168",
	"NORUTEC169",
	"NORUTEC170",]


class SignUp extends React.Component {

	
	constructor() {
		super();
		this.state = {
			loading: false,
			state: 'Aguascalientes',
			sex: 'Masculino',
			code: 'NORUTEC001',
			
			redirect: false,
			signing: false,
		
			values: {},
			loggedInVia:false,
			showFrame:false,
			queryInsert:"",
			regSites:0,
			showCatFrame:false,
			showMGFrame:false,
			showInFrame:false,
			showGasFrame:false,
			origins:{},
			//email: 'demo@example.com',
			//password: 'demo#123'
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.setRedirect = this.setRedirect.bind(this);
		this.uploadImage = this.uploadImage.bind(this);

		//this.handleSignIn = this.handleSignIn.bind(this);
		this.handleSexClick = this.handleSexClick.bind(this);
		this.handleCodeClick = this.handleCodeClick.bind(this);
		this.handleCodeClick = this.handleCodeClick.bind(this);

		this.handleStateClick = this.handleStateClick.bind(this);
		this.handleVisitClick = this.handleVisitClick.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);

	}
	isValidEmail(email) {
		return /\S+@\S+\.\S+/.test(email);
	  }
	componentDidMount() {
		this.setState({ state: 'Aguascalientes', sex: 'Masculino', visit: 'SI', code: "NORUTEC001", isGoing: true });
	
		
	}


	handleChange = (e) => {
		if (this.state.uploadingImage == undefined || this.state.uploadingImage == false) {

			var img = e.file.originFileObj
			this.setState({ uploadingImage: true });
		
			if (this.beforeUpload(e.file)) {
				var _this = this;

				let reader = new FileReader();
				reader.addEventListener('load', () => {
					let imageLocalUrl = reader.result;
					var obj = {
						imageLocalUrl,
						currentAvatar:img,
						uploadingImage: false
					}
					_this.setState(obj)
					console.log(obj)
				
				});
				reader.readAsDataURL(img);
			} else {
				setInterval(() => this.setState({ uploadingImage: false }), 1000);

			}
		} else {
			console.log(this.state.uploadingImage)
			console.log("loading image")
		}
		
	};

	checkImageURL(URL) {
		fetch(URL)
			.then((res) => {
				if (res.status == 404) {
					return false;
				} else {
					return true;
				}
			})
			.catch((err) => {
				return false;
			});
	}

	beforeUpload(file) {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');

		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Image must smaller than 2MB!');

		}
		return isJpgOrPng && isLt2M;
	};

	uploadImage(){
		if(this.state.currentAvatar != undefined){
			console.log("STARTING UPLOAD AVATAR...")
	
			let reader = new FileReader();
		
				reader.addEventListener('load', () => this.submitImageToFirebase(reader.result));
				reader.readAsDataURL(this.state.currentAvatar);
		}else{
			console.log("AVATAR NOT SELECTED")
			//this.props.history.push('/');
			this.setRedirect();
			
		}
	}

	submitImageToFirebase(value) {
		console.log("CasosIlaprazolChinoIn SUMBITTNG");
		if(this.state.uploadingImage == false){
			console.log("CasosIlaprazolChinoInUPLOAGIND");
			this.setState({ uploadingImage: true });
			console.log("value")
		console.log(value)
		var _this = this;
		// Create a root reference
		var storageRef = storage.ref();
	

		var extension = this.state.currentAvatar.name.split('.').pop();
				var name = this.state.values.email.replace("@","-")
				var nameWithoutDotCom = name.substring(0, name.length - 4);
				var finalNname = nameWithoutDotCom+"."+extension

		// Create a reference to 'mountains.jpg'
		var newImageRef = storageRef.child(finalNname);
		var stripedImage = value.substring(value.indexOf('base64') + 7, value.length);

		newImageRef.putString(stripedImage, 'base64').then(function (snapshot) {
			console.log('Uploaded a base64 string!');
			snapshot.ref.getDownloadURL().then(function (imageUrl) {
				console.log("CasosIlaprazolChinoIn UPLOADED!!!!")
				_this.setState({
					imageUploaded:true
				})
				//_this.props.history.push('/');
				_this.setRedirect();
				//_this.register(err, values)
				console.log(imageUrl);
			});
		});
		}else{
			console.log("CasosIlaprazolChinoIn ALREADY UPLOADING");
			if(this.state.imageUploaded == true){
				console.log("CasosIlaprazolChinoIn ALREADY UPLOADED REDIRECTING");
				this.setRedirect();
			}
		}
	}

	handleInputChange(e) {
		//console.log(e);
		//console.log(e);
		this.setState({ isGoing: e });
	}
	handleVisitClick(e) {
		//console.log(e);
		//console.log(e);
		this.setState({ sex: e });
	}
	handleCodeClick(e) {
		//console.log(e);
		//console.log(e);
		this.setState({ code: e });
	}
	handleSexClick(e) {
		//console.log(e);
		//console.log(e);
		this.setState({ sex: e });
	}
	handleStateClick(e) {
		this.setState({ state: e });
	}

	setRedirect = () => {
		this.setState({
			redirect: true
		});
	};
	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to="/profile" />;
		}
	};

	register(err, values) {
		
		
			if(this.state.currentAvatar != undefined){
				var extension = this.state.currentAvatar.name.split('.').pop();
				var name = values.email.replace("@","-")
				var nameWithoutDotCom = name.substring(0, name.length - 4);
				var finalNname = nameWithoutDotCom+"."+extension

				values.avatar = 'https://firebasestorage.googleapis.com/v0/b/casosilaprazolchinoin.appspot.com/o/'+finalNname+'?alt=media';
			}else{
				values.avatar = 'https://firebasestorage.googleapis.com/v0/b/casosilaprazolchinoin.appspot.com/o/Doctor.png?alt=media';
			}
			if (values.city === undefined) {
				values.city = ""
			}
			if (values.phone === undefined) {
				values.phone = ""
			}
			var _this = this;


			//if(this.state.isGoing == false){
			//if(values.age)
			this.setState({ sex: values.sex });
			this.setState({ code: values.code });

			if (codes.indexOf(values.code) != -1) {
		
				this.setState({ state: values.state });
				//console.log('CALLING LOADER');
				//this.props.showAuthLoader();

				this.setState({ values: values });
				var age = values.age
				values.age = age.format("DD-MM-YYYY")
				values.email = values.email.trim().toLowerCase()

				//console.log(values);
				database.collection('users').where('email', '==', values.email).get().then(function (snapshot) {
					if (snapshot.docs.length == 0) {
						_this.props.showAuthLoader();
						//console.log(Moment().format("DD/MM/YYYY'T'hh:mm:ss"))
						if (values.password.length >= 6) {
							values.registration_date = Moment().format("DD/MM/YYYY'T'hh:mm:ss")
							
							database
								.collection('users')
								.add(values)
								.then(function (docRef) {
									//console.log('WRITING USER DATA DONE');
									_this.props.userSignUp(values);
								})
								.catch(function (error) {
									//console.error('Error adding document: ', error);
								});
						} else {
							//console.log('Short Password');
							message.error('La contraseña debe de tener al menos 6 caracteres');

							//_this.props.alertMessage = 'Este usuario ya esta registrado';
						}
					} else {
						message.error('Este usuario ya esta registrado');

					}
				});
			} else {
				message.error('Código de acceso inválido');
			}
	}

	/*handleSignIn = (err, values) => {
	
		
		if (!err) {
			var _this = this;
			if(this.state.currentAvatar != undefined){
				console.log("IMAGE DETECTED")
				if(this.state.imageUploaded){
					console.log("IMAGE UPLOADED, REGISTERING...");

					this.register(err, values)
				}else{
					console.log("IMAGE UPLOADING...")

					this.uploadImage(err, values);
				}
			}else{
				console.log("IMAGE NOT DETECTED")
				this.register(err, values)	
			}
			this.register(err, values)
		} else {
			//console.log('ERROR IS NOT NULL');
		}
	};*/

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			this.register(err, values)
	
		});
	};

	componentDidUpdate() {
		if (this.props.showMessage) {
			setTimeout(() => {
				this.props.hideMessage();
			}, 100);
		}
		if (!this.state.signing) {
			var refThis = this;
			var via  = this.state.loggedInVia;
			if (this.props.authUser !== null) {
				auth.onAuthStateChanged((user) => {
					if (user) {
						
							console.log("CasosIlaprazolChinoIn via:"+via)
							if(refThis.state.currentAvatar != undefined){
								console.log("CasosIlaprazolChinoIn STARTING UPLOAD AVATAR...")
									if(refThis.state.uploadingImage == false){
										let reader = new FileReader();
							
										reader.addEventListener('load', () => refThis.submitImageToFirebase(reader.result));
										reader.readAsDataURL(refThis.state.currentAvatar);
									}else{
										console.log("CasosIlaprazolChinoIn ALREADY UPLOADING");
										if(refThis.state.imageUploaded == true){
											console.log("CasosIlaprazolChinoIn ALREADY UPLOADED REDIRECTING");
											refThis.setRedirect();
										}
	
									}
	
							}else{
								console.log("CasosIlaprazolChinoIn AVATAR NOT SELECTED, waiting event...")
							
								refThis.setRedirect();
								
							}
						
						//this.setRedirect();
					} else {
						
						if (refThis.state.values.email && refThis.state.values.password) {
							
							refThis.setState({ signing: true });
							auth.signInWithEmailAndPassword(refThis.state.values.email, refThis.state.values.password)
								.then((authUser) => {
									refThis.setRedirect();							
								})
								.catch((error) => error);
						} else {
							//console.log('EMPTY VALUES');
						}
					}
				});
			} else {
				//console.log('AUTH USER IS NULL');
			}
		}
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const { showMessage, loader, alertMessage } = this.props;

		const imageUrl = this.state.imageLocalUrl;
		return (
			<div className="gx-app-login-wrap" >
				<HorizontalDefault /> 
				<div className="gx-app-login-wrap" id="login-cont">

					<div className="gx-app-login-container">

						<div className="gx-app-login-main-content">

							{this.renderRedirect()}

							<div className="gx-app-login-content">
								<Row><Col span="1"></Col><Col span="17">
									<img alt="example" src={require('assets/images/asset_3.png')} /> 	<br></br>	<br></br>	<br></br>	
								</Col><Col span="3" style={{ marginTop: "auto" }}>

										<FormItem style={{ marginLeft: "auto" }}>
											{getFieldDecorator('avatar', {
												rules: [
													{
														required: false,
														message: 'Please upload your avatar!'
													}
												]
											})(
												<center>
													<Upload
														name="avatar"
														listType="picture-card"
														className="avatar-uploader"
														showUploadList={false}
														//action="//jsonplaceholder.typicode.com/posts/"
														onChange={this.handleChange}
													>
														{imageUrl ? <img src={imageUrl} alt="avatar" /> : <span>Cargar fotografía</span>}
													</Upload>
												</center>
											)}
										</FormItem>
									</Col>

								</Row>
								{/* <Row>
								<Col span="8" />
								<Col span="8">
									{' '}
								 <img alt="example" src={require('assets/images/asset_3.png')} /> 
								</Col>
								<Col span="8" />
							</Row>
							<br /> */}
								<Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
									<Row>
										<Col xl={12} lg={12} md={12} sm={12} xs={12}>
											<FormItem>
												{getFieldDecorator('name', {
													rules: [{ required: true, message: 'Por favor escribe tu nombre' }]
												})(<Input placeholder="Nombre" />)}
											</FormItem>
											<FormItem>
												{getFieldDecorator('paternal_lastname', {
													rules: [{ required: true, message: 'Ingrese su apellido paterno' }]
												})(<Input placeholder="Apellido Paterno" />)}
											</FormItem>

											<FormItem>
												{getFieldDecorator('maternal_lastname', {
													rules: [{ required: true, message: 'Ingrese su apellido materno' }]
												})(<Input placeholder="Apellido Materno" />)}
											</FormItem>

											<FormItem>
												{getFieldDecorator('sex', {
													rules: [{ required: true, message: 'Por favor seleccione su sexo' }]
												})(
													<Select
														name="sex"
														onChange={this.handleSexClick}
														value={this.state.sex}
														placeholder="Sexo"
													>
														<Option value="Masculino">Masculino</Option>
														<Option value="Femenino">Femenino</Option>
													</Select>
												)}
											</FormItem>

											<FormItem>
												{getFieldDecorator('age', {
													rules: [
														{
															required: true,
															message: 'Por favor seleccione su fecha de nacimiento'
														}
													]
												})(
													<DatePicker
														className="gx-mb-3 gx-w-100"
														showTime={false}
														showToday={false}
														format="DD/MM/YYYY"
														placeholder="Fecha de nacimiento"
													/>
												)}
											</FormItem>


											<FormItem>
												{getFieldDecorator('state', {
													rules: [{ required: true, message: 'Por favor ingrese su estado' }]
												})(
													<Select
														name="state"
														onChange={this.handleStateClick}
														value={this.state.state}
														placeholder="Estado"
													>
														<Option value="Aguascalientes">Aguascalientes</Option>

														<Option value="Baja California">Baja California</Option>

														<Option value="Baja California Sur">Baja California Sur</Option>

														<Option value="Campeche">Campeche</Option>

														<Option value="Coahuila de Zaragoza">Coahuila</Option>

														<Option value="Colima">Colima</Option>

														<Option value="Chiapas">Chiapas</Option>

														<Option value="Chihuahua">Chihuahua</Option>

														<Option value="Ciudad de México">Ciudad de México</Option>

														<Option value="Durango">Durango</Option>

														<Option value="Guanajuato">Guanajuato</Option>

														<Option value="Guerrero">Guerrero</Option>

														<Option value="Hidalgo">Hidalgo</Option>

														<Option value="Jalisco">Jalisco</Option>

														<Option value="México">México</Option>

														<Option value="Michoacán">Michoacán</Option>

														<Option value="Morelos">Morelos</Option>

														<Option value="Nayarit">Nayarit</Option>

														<Option value="Nuevo León">Nuevo León</Option>

														<Option value="Oaxaca">Oaxaca</Option>

														<Option value="Puebla">Puebla</Option>

														<Option value="Querétaro">Querétaro</Option>

														<Option value="Quintana Roo">Quintana Roo</Option>

														<Option value="San Luis Potosí">San Luis Potosí</Option>

														<Option value="Sinaloa">Sinaloa</Option>

														<Option value="Sonora">Sonora</Option>

														<Option value="Tabasco">Tabasco</Option>

														<Option value="Tamaulipas">Tamaulipas</Option>

														<Option value="Tlaxcala">Tlaxcala</Option>

														<Option value="Veracruz">Veracruz</Option>

														<Option value="Yucatán">Yucatán</Option>

														<Option value="Zacatecas">Zacatecas</Option>
													</Select>
												)}
											</FormItem>
											<FormItem>
											{getFieldDecorator('remember', {
												rules: [{ required: true, message: 'Debes aceptar los términos y condiciones' }],
												valuePropName: 'checked',
												initialValue: true
											})(
												<Input
													name="isGoing"
													type="checkbox"
													checked={this.state.isGoing}
													onChange={this.handleInputChange} ></Input>

											)}

											<span className="gx-link gx-signup-form-forgot">
												<IntlMessages id="appModule.iAccept" />
												<a className="gx-login-form-forgot" href="https://www.innovagastrochinoin.com/avisoprivacidad.html" target="_blank">	<IntlMessages id="appModule.termAndCondition" /></a>


											</span>
										</FormItem>

										</Col>
										<Col xl={12} lg={12} md={12} sm={12} xs={12}>
											<FormItem>
												{getFieldDecorator('city', {
													rules: [{ required: false, message: 'Ingrese la ciudad de residencia' }]
												})(<Input placeholder="Ingrese la ciudad de residencia" />)}
											</FormItem>


											<FormItem>
												{getFieldDecorator('license', {
													rules: [{ required: true, message: 'Ingrese su cédula profesional' }]
												})(<Input placeholder="Ingrese su cédula profesional" />)}
											</FormItem>
											<FormItem>
												{getFieldDecorator('specialty', {
													rules: [{ required: true, message: 'Ingrese su especialidad' }]
												})(<Input placeholder="Ingrese su especialidad" />)}
											</FormItem>

											<FormItem>
												{getFieldDecorator('phone', {
													rules: [{ required: false, message: 'Ingrese su teléfono' }]
												})(<Input placeholder="Ingrese su teléfono" />)}
											</FormItem>

											<FormItem>
												{getFieldDecorator('code', {
													rules: [{ required: true, message: 'Ingrese su Clave de acceso' }]
												})(<Input placeholder="Ingrese su Clave de acceso" />)}
											</FormItem>


											<FormItem>
												{getFieldDecorator('email', {
													rules: [
														{
															required: true,
															type: 'email',
															message: 'The input is not valid E-mail!'
														}
													]
												})(<Input placeholder="Email" />)}
											</FormItem>
											<FormItem>
												{getFieldDecorator('password', {
													rules: [{ required: true, message: 'Por favor escribe tu contraseña' }]
												})(<Input type="password" placeholder="Password" />)}
											</FormItem>
										</Col>
									</Row>
									<div class="centered-login" >

									

<FormItem>
	<Button
		id="sesion"
		type="primary"
		className="gx-mb-0"
		htmlType="submit"
		onClick={this.handleSubmit}
	>
		<IntlMessages id="app.userAuth.signUp" className="loginmssg" />
	</Button>
	<span>
		<IntlMessages id="app.userAuth.or" />
	</span>{' '}
	<Link to="/signin">
		<IntlMessages id="app.userAuth.signIn" />
	</Link>
</FormItem>
</div>
								</Form>

							</div>

							{loader && (
								<div className="gx-loader-view">
									<CircularProgress />
								</div>
							)}
							{showMessage && message.error(alertMessage)}
						</div>
						<span id="version-code">
							{version}</span>
					</div>
				</div>
				<br></br>	<br></br>	<br></br>
				{<img class="footer-image" src={require("assets/images/ACOMODO-new.png")} alt="casosilaprazolchinoin" title="casosilaprazolchinoin" />}

			</div>
		);
	}
}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({ auth }) => {
	const { loader, alertMessage, showMessage, authUser } = auth;
	return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
	userSignUp,
	hideMessage,
	userSignIn,
	showAuthLoader,
	userFacebookSignIn,
	userGoogleSignIn,
	userGithubSignIn,
	userTwitterSignIn
})(WrappedSignUpForm);
