import React from 'react';
import { Button, Checkbox, Form, Icon, Input, message, Dropdown, Menu, Card, Row, Col, Layout } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth, database } from '../firebase/firebase';
import { version } from "util/config";
import Cookies from 'js-cookie';

import {
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';
import Topbar from "./Topbar/index";
import HorizontalDefault from "./Topbar/HorizontalDefault/index";

const FormItem = Form.Item;

class SignIn extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		  email: "",
		  pass: "",
		  showFrame: false,
		  loggedInVia:false,
		  loggedSites:0,

		  
		  showCatFrame:false,
			showMGFrame:false,
			showInFrame:false,
			showGasFrame:false,
			origins:{},
		};
	  }
	
	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.setState({ email: values.email, pass: values.password });
				this.props.showAuthLoader();
				this.props.userSignIn(values);
			}
		});
	};

	handleButtonClick(e) {
		message.info('Click on left button.');
		//console.log('click left button', e);
	}

	handleMenuClick(e) {
		message.info('Click on menu item.');
		//console.log('click', e);
	}
	 isValidEmail(email) {
		return /\S+@\S+\.\S+/.test(email);
	  }
	  componentDidUpdate() {
		if (this.props.showMessage) {
			setTimeout(() => {
				this.props.hideMessage();
			}, 100);
		}
		if (this.props.authUser !== null) {
			var _this = this;
			auth.onAuthStateChanged((user) => {
				//if(this.state.loggedInVia == false){
					console.log("Logged in via true")
					if (user) {
						
						/*if(_this.state.showCatFrame == false){
							window.addEventListener('message', function (event) {
								console.log(event);
								if(event.data == "remote-login-success"){
									_this.state.origins[event.origin] = true;
								}
								if(Object.keys(_this.state.origins).length == 4){
									_this.props.history.push('/');
								}
								
							});
							_this.setState({ showCatFrame: true });
							setTimeout(() => {
								_this.setState({ showMGFrame: true });
							  }, 500)
							  setTimeout(() => {
								_this.setState({ showInFrame: true });
							  }, 500)
							  setTimeout(() => {
								_this.setState({ showGasFrame: true });
								setTimeout(() => {
									_this.props.history.push('/');
								 }, 5000);
							  }, 500)							
						}*/
						Cookies.set('user_email',_this.state.email, { domain: '.gastroin.com' })
						Cookies.set('user_password',_this.state.pass, { domain: '.gastroin.com' })
						Cookies.set('logged_in', true, { domain: '.gastroin.com' })

						_this.props.history.push('/');

					} else {
						localStorage.removeItem('user_id');
      					localStorage.removeItem('user_email');
      					localStorage.removeItem('user_password');
						Cookies.remove('user_email', { domain: '.gastroin.com' })
						Cookies.remove('user_password', { domain: '.gastroin.com' })
						Cookies.set('logged_in', false, { domain: '.gastroin.com' })

					}
				/*}else{
					window.parent.postMessage('remote-login-success', '*')
					console.log("CasosIlaprazolChinoIn: SESIÓN INICIADA");

				}*/
			});
		} else {
			//console.log('AUTH USER IS NULL');
		}
	}
	componentDidMount() {


		var email = Cookies.get('user_email', { domain: '.gastroin.com' })
		var pass = Cookies.get('user_password', { domain: '.gastroin.com' })
		var logged = Cookies.get('logged_in', { domain: '.gastroin.com' })
		console.log(email)
		console.log(pass)
		console.log(logged)
		if(logged != undefined){
			if(logged+"" === "true") {
				if(email && pass){
					console.log("YES COOKIES")
				var values = { email: email, password: pass }
				this.setState({ email: email, pass: pass });
				this.props.showAuthLoader();
				this.props.userSignIn(values);
				}else{
					console.log("Empty credentials")
				}
			}else{
				console.log("NOCOOKIES")
			}
		}

		/*const query = new URLSearchParams(this.props.location.search);
		const givenEmail = query.get('passedemail')
		const givenPassword = query.get('passedpassword')
		console.log(givenEmail)	
		console.log(givenPassword)		
		var _this = this;
		if(givenEmail != undefined && givenPassword != undefined){
			if(givenEmail != "" && givenPassword != ""){
				localStorage.setItem('user_email', givenEmail);
				localStorage.setItem('user_password', givenPassword);
				console.log("CasosIlaprazolChinoIn: PARÁMETROS ACEPTADOS");
				if(this.isValidEmail(givenEmail)){
					this.setState({loggedInVia: true});
					console.log("CasosIlaprazolChinoIn: INICIANDO SESIÓN");
					this.props.userSignIn({email:givenEmail,password:givenPassword});
				}
			}
		}*/
		
		/*else{
			var email = localStorage.getItem("user_email")
			var pass =  localStorage.getItem("user_password")
			var id = localStorage.getItem('user_id')

			var _this = this; 
			if(email != undefined && pass != undefined){
				if(email != "" && pass != ""){
					console.log("YES LCS")

					if(id != null || id != undefined){
							console.log("id was not null")
						this.props.history.push('/');
					}else{
						console.log("id was null, searching for user")

						if(/\S+@\S+\.\S+/.test(email)){
							database.collection('users').where('email', '==', email).get().then(function (snapshot) {
								if (snapshot.docs.length != 0) {
									_this.props.userSignIn({email:email, password:pass });
								}else{
									console.log("USUARIO NO EXISTE");
								
								}
							});
						}
					}
					}

			}else{
				console.log("NOLCS")
			}
		}*/
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const { showMessage, loader, alertMessage } = this.props;
		return (
			<div className="gx-app-login-wrap">

				<HorizontalDefault />
				<div className="gx-app-login-wrap" id="login-cont">

					<div className="gx-app-login-container">

						<div className="gx-app-login-main-content">

							<div className="gx-app-login-content">

								<center>
									<img alt="example" src={require('assets/images/asset_3.png')} /> 	
								

								</center>
								<br></br>	<br></br>	<br></br>
								<Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
									<FormItem>
										{getFieldDecorator('email', {
											rules: [
												{
													required: true,
													type: 'email',
													message: 'Por favor introduce un correo electrónico válido'
												}
											]
										})(<Input placeholder="Email" />)}
									</FormItem>
									<FormItem>
										{getFieldDecorator('password', {
											rules: [{ required: true, message: 'Por favor introduce tu contraseña' }]
										})(<Input type="password" placeholder="Password" />)}
									</FormItem>
		
									
									<div class="centered-login" >
							           								
																	   <br></br>
																	   <FormItem>
																		   <Button id="sesion" type="primary" className="gx-mb-0" htmlType="submit">
																			   <IntlMessages id="app.userAuth.signIn" />
																		   </Button>
																	   
																			   <br></br>
																		   <Link to="/signup">
																			   <span>  &nbsp;  &nbsp; &nbsp;o Regístrate</span>
																		   </Link>
																		   <br></br>
																		   <Link className="gx-login-form-forgot" to="/reset-password">Olvidé mi contraseña</Link>
								   
																	   </FormItem>
																	   <br></br>
																	   <br></br>
								   
								   
																   </div>

								</Form>


							</div>

							{loader ? (
								<div className="gx-loader-view">
									<CircularProgress />
								</div>
							) : null}
							{showMessage ? message.error(alertMessage.toString()) : null}

						</div>

						<span id="version-code">
							{version}</span>
					</div>

				</div>
				{<img class="footer-image" src={require("assets/images/ACOMODO-new.png")} alt="CasosIlaprazolChinoIn" title="CasosIlaprazolChinoIn" />}

			</div>
		);
	}
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
	const { loader, alertMessage, showMessage, authUser } = auth;
	return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
	userSignIn,
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGoogleSignIn,
	userGithubSignIn,
	userTwitterSignIn
})(WrappedNormalLoginForm);
