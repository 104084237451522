import React, { Component } from 'react';
import {
    Col,
    Row,
    Button,
    Card,
    Modal,
    Radio
} from 'antd';
import Moment from 'moment';

import { auth, database } from '../../../firebase/firebase';
import { Redirect } from 'react-router-dom';
const RadioGroup = Radio.Group;

class VideoQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            danger: false,
            visible: false,
            confirmLoading: false,
            solvedQuestionData: {},
            solvedQuestionId: -1,
            value: -1
        };
        this.showModal = this
            .showModal
            .bind(this);
        this.handleOk = this
            .handleOk
            .bind(this);
        this.handleCancel = this
            .handleCancel
            .bind(this);
        this.writeNewAttempt = this
            .writeNewAttempt
            .bind(this);
        this.getQuestionOptions = this
            .getQuestionOptions
            .bind(this);
    }
    writeNewAttempt() {
        //console.log('NO ATTEMP, WRITING NEW ONE');
        var _this = this;
        var ref = database.collection(_this.props.path).doc(_this.props.question.id)
        ref.set({
            question: _this.props.question.id,
            selected_option: _this.state.value,
            was_correct: _this.props.question.data().correct_option == _this.state.value? 'true': 'false',
            timestamp: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
        }).then(function () {
            database.doc(_this.props.path+"/"+_this.props.question.id).get().then(function (doc) {
                //console.log('Document successfully written!');
                //console.log(doc);
                _this.setState({ solvedQuestionData: doc.data, solvedQuestionId: doc.id });
                _this.props.onAddAnswer(_this.props.question);
                _this.props.setNextLimit()
                setTimeout(() => {
                    _this.setState({ visible: false, confirmLoading: false });
        
                    _this.props.hideAllQuestionModals(_this.props.index);
                }, 300);
            });
            })
            .catch(function (error) {
                console.error('Error writing document: ', error);
            });
    }
    isEmpty(obj) {

        // null and undefined are "empty"
        if (obj == null)
            return true;

        // Assume if it has a length property with a non-zero value that that property
        // is correct.
        if (obj.length > 0)
            return false;
        if (obj.length === 0)
            return true;

        // If it isn't an object at this point it is empty, but it can't be anything
        // *but* empty Is it empty?  Depends on your application.
        if (typeof obj !== "object")
            return true;

        // Otherwise, does it have any properties of its own? Note that this doesn't
        // handle toString and valueOf enumeration bugs in IE < 9
        for (var key in obj) {
            if (hasOwnProperty.call(obj, key))
                return false;
        }

        return true;
    }

    showModal = () => {
        if (!this.props.disabledButtons) {
            this.setState({ visible: true });
        }
    };

    handleOk = () => {
        var _this = this;
        if(_this.state.value != -1) {
            _this.setState({ danger: false });

        if (!_this.props.solvedQuestion && _this.state.solvedQuestionId == -1) {
            _this.writeNewAttempt();
        } else {
            var _this = this;

            var ref = database.doc(this.props.path + '/' + this.state.solvedQuestionId);
            var localDoc = _this.state.solvedQuestionData;

            var date = Moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
            ref
                .update({
                    question: _this.props.question.id,
                    selected_option: _this.state.value,
                    was_correct: _this
                        .props
                        .question
                        .data()
                        .correct_option == _this.state.value
                        ? 'true'
                        : 'false',
                    timestamp: date
                })
                .then(function () {
                    localDoc.question = _this.props.question.id;
                    localDoc.selected_option = _this.state.value;
                    localDoc.was_correct = _this
                        .props
                        .question
                        .data()
                        .correct_option == _this.state.value
                        ? 'true'
                        : 'false';
                    localDoc.timestamp = date;
                    _this.setState({ solvedQuestionData: localDoc });
                    _this.props.onUpdateAnswer(localDoc, _this.props.index);
                    _this.props.setNextLimit()
                    setTimeout(() => {
                        _this.setState({ visible: false, confirmLoading: false });
            
                        _this.props.hideAllQuestionModals(_this.props.index);
                    }, 300);
                })
                .catch(function (error) {
                    console.error('Error updating document: ', error);
                });
        }
        this.setState({ confirmLoading: true });
    }else{
        _this.setState({ danger: true });

    }
        
        
       
    };
    handleCancel = () => {
        this.setState({ visible: false });
    };
    onChange = (e) => {
        //console.log('radio checked', e.target.value);
        this.setState({ value: e.target.value });
    };

    getQuestionOptions() {
        var _this = this;
        database
            .collection('options')
            .where('question', '==', this.props.question.id)
            .get()
            .then(function (querySnapshot) {
                if (querySnapshot.docs.length > 0) {
                    var sortedOptions = querySnapshot
                        .docs
                        .sort(function (a, b) {
                            if (a.data().order < b.data().order) {
                                return -1;
                            }
                            if (a.data().order > b.data().order) {
                                return 1;
                            }
                            return 0;
                        });

                    _this.setState({ options: sortedOptions });
                }
            });
    }

    componentDidMount() {
        this.getQuestionOptions();
        if (this.props.solvedQuestion) {
            // //console.log('GIVEN SOLVED QUESTION'); //console.log(this.props.solvedQuestion);
            this.setState({
                solvedQuestionData: this
                    .props
                    .solvedQuestion
                    .data(),
                solvedQuestionId: this.props.solvedQuestion.id,
                value: this
                    .props
                    .solvedQuestion
                    .data()
                    .selected_option
            });
        } else {
            //console.log('NO SOLVED QUESTION FOR THIS ONE');
        }
    }

    render() {
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px'
        };
        return (
            <div>

                <Modal
                    className='fack'
                    centered
                    key={this.props.index}
                    title={this.props.question.data().title}
                    visible={this.props.question.visible}
                    keyboard={false}
                    closable={false}
                    footer={[
                        (this.state.danger)?
                        <Button onClick={this.handleOk}>
                       Selecciona una opción para continuar
                    </Button>:   <Button onClick={this.handleOk}>
                            Guardar y continuar
                        </Button>
                      
                    ]}

                    confirmLoading={this.state.confirmLoading}
                    onCancel={(question) => this.handleCancel(question)}>
                    {this
                        .props
                        .question
                        .data()
                        .image
                        ? (<img
                            src={this
                                .props
                                .question
                                .data()
                                .image != 'https://firebasestorage.googleapis.com/v0/b/casosilaprazolchinoin.appspot.com/o/placeholder.png?alt=media'
                                ? (this.props.question.data().image)
                                : null} />)
                        : null}
                    <RadioGroup onChange={this.onChange} value={this.state.value}>
                        {this
                            .state
                            .options
                            .map((option, index) => (
                                <div>
                                    <Radio className='dots' style={radioStyle} value={option.id} key={option.id}>
                                        {option
                                            .data()
                                            .title}
                                    </Radio>
                                    <label>
                                        {' '}
                                        <img src={option.image} />
                                    </label>
                                </div>
                            ))}
                    </RadioGroup>
                </Modal>
            </div>
        );
    }
}
export default VideoQuestion;

/*
const Icon = () => {
  return (<Card className="gx-card" title="Icon">
      <Button type="primary" size="large" icon="search"/>
      <Button type="primary" size="large" icon="search">Search</Button>
      <Button shape="circle" icon="search"/>
      <Button icon="search">Search</Button>
      <br/>
      <Button shape="circle" icon="search"/>
      <Button icon="search">Search</Button>
      <Button type="dashed" shape="circle" icon="search"/>
      <Button type="dashed" icon="search">Search</Button>
    </Card>
  );
};*/
