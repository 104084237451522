import React, { Component } from 'react';
import { Col, Row, Button, Card, Modal, Radio, Icon } from 'antd';

import Moment from 'moment';
import localization from 'moment/locale/es'

import SweetAlert from 'react-bootstrap-sweetalert';

import { auth, database, functions } from '../../firebase/firebase';
import { Redirect } from 'react-router-dom';
import Question from './Question';
import { fail } from 'assert';
const RadioGroup = Radio.Group;

class Evaluation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {},
			seriesId: -1,
			evId: -1,
			serie: {},
			path: '',
			evaluation: {},
			currentSolvedEvaluation: {},
			solvedQuestions: [],
			uSolvedQuestions: [],
			success: false,
			maxTries: false,
			approved: false,
			denied: false,
			certificateWinner: false,
			failure: false,
			disabledButtons: false,
			gradeMessage: '',
			solvedQuestionsLoaded: false,
			solvedQuestionsPath: '',
			solvedEvPath: '',
			questions: [],
			danger: false,
			loading: false,
			grade: '',
			graded: false,
			gradedButton: 'Calificar',
			redirect: false,
			redirectHome: false,

			modalText: 'Content of the modal',
			visible: false,
			confirmLoading: false
		};

		this.showModal = this.showModal.bind(this);
		this.handleOk = this.handleOk.bind(this);
		this.getCertificateReady = this.getCertificateReady.bind(this);
		this.checkIfCertificateOrMessage = this.checkIfCertificateOrMessage.bind(this);

		this.handleCancel = this.handleCancel.bind(this);
		this.setRedirect = this.setRedirect.bind(this);
		this.renderRedirect = this.renderRedirect.bind(this);
		this.onAddAnswer = this.onAddAnswer.bind(this);
		this.onUpdateAnswer = this.onUpdateAnswer.bind(this);
		this.gradeEvaluation = this.gradeEvaluation.bind(this);
		this.getQuestionsInformation = this.getQuestionsInformation.bind(this);
		this.getEvaluationInformation = this.getEvaluationInformation.bind(this);
		this.getQuestionOptions = this.getQuestionOptions.bind(this);
	}

	onAddAnswer(answerDoc) {
		//console.log('ADDING NEW ANSER');
		this.state.uSolvedQuestions.push(answerDoc);
		//console.log(this.state.uSolvedQuestions);
		var decimalPorcentage = ((this.state.uSolvedQuestions.length / this.state.questions.length) * 100)
		var porcentage = Math.floor(decimalPorcentage)
		if(porcentage>100){
			porcentage = 100
		}
		if (this.state.currentSolvedEvaluation.status != 'En curso') {
			var ref = database.doc(this.state.solvedEvPath);
			ref.update({
				status: 'En curso',
				porcentage: porcentage + ''
			});
			this.state.currentSolvedEvaluation.status = 'En curso';
			this.state.currentSolvedEvaluation.porcentage = porcentage + '';

			//console.log('ONCOURSE update');
		} else {
			var ref = database.doc(this.state.solvedEvPath);
			ref.update({
				porcentage: porcentage + ''
			});
			this.state.currentSolvedEvaluation.porcentage = porcentage + '';
		}
	}

	onUpdateAnswer(answerDoc, index) {
		//console.log('Updating NEW ANSER');
		this.state.uSolvedQuestions[index] = answerDoc
		//console.log(this.state.uSolvedQuestions);
		var decimalPorcentage = ((this.state.uSolvedQuestions.length / this.state.questions.length) * 100)
		var porcentage = Math.floor(decimalPorcentage)
		if(porcentage>100){
			porcentage = 100
		}
		if (this.state.currentSolvedEvaluation.status != 'En curso') {
			var ref = database.doc(this.state.solvedEvPath);
			ref.update({
				status: 'En curso',
				porcentage: porcentage + ''
			});
			this.state.currentSolvedEvaluation.status = 'En curso';
			this.state.currentSolvedEvaluation.porcentage = porcentage + '';

			//console.log('ONCOURSE update');
		} else {
			var ref = database.doc(this.state.solvedEvPath);
			ref.update({
				porcentage: porcentage + ''
			});
			this.state.currentSolvedEvaluation.porcentage = porcentage + '';
		}
	}

	showModal = () => {
		this.setState({
			visible: true
		});
	};
	handleOk = () => {
		this.setState({
			confirmLoading: true
		});
		setTimeout(() => {
			this.setState({
				visible: false,
				confirmLoading: false
			});
		}, 300);
	};
	handleCancel = (question) => {
		//console.log('Clicked cancel button');
		if (question.visible) {
			question.visible = false;
		}
	};

	setRedirect = () => {
		this.setState({
			redirect: true
		});
	};
	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to="/signin" />;
		}
	};

	renderRedirectHome = () => {
		if (this.state.redirectHome) {
			return <Redirect to={'series/' + this.state.seriesId+'/evaluations'} />;
		}
	};
	getCasesInformation() {
		var _this = this;
		var query = database
		  .collection("clinic_cases")
		  .where("series", "==", _this.state.seriesId)
		  query = query.where("active", "==", true)
		  query.get()
		  .then(function (querySnapshot) {
			if (querySnapshot.docs.length != 0) {
	
				console.log("getNumberOfReadCases")
				_this.setState({ totalCases: querySnapshot.docs.length });
				_this.getNumberOfReadCases()
			  
			}
		  });
	  }

	  getNumberOfReadCases() {
		console.log("getNumberOfReadCases...")
		var _this = this;
		var path = "users/" + _this.state.userId + '/read_cases';
		
		database
		  .collection(path).where('series_id', '==', _this.state.urlId)
		  .get()
		  .then(function (querySnapshot) {
			//console.log('OPTIONS'); //console.log(querySnapshot);
			if (querySnapshot.docs.length != 0) {
	
			  var por = parseInt((querySnapshot.docs.length / _this.state.totalCases) * 100)
			  _this.setState({ readPorcentage: por, readCases: querySnapshot.docs.length});
			  
			  if (this.state.porcentage > this.state.series.read_cases_porcentage_certification) {
				console.log("getCertificateReady")
				_this.setState({ certificateWinner: true });
				_this.getCertificateReady();
			  }
	
	
			} else {
			  _this.setState({ porcentage: 0, readCases: 0, loaded: true });
			  console.log("0% reare case")
			}
		  }).catch(function (error) {
			_this.setState({ porcentage: 0, readCases: 0, loaded: true });
		  });
	  }

	getAllCertificateData(obi) {
		var _this = this;

		database.collection('users/' + _this.state.user.id + '/accesed_series/' + _this.state.accesedSerie.id + '/solved_evaluations')
		.get()
		.then(function(querySnapshot) {
				var filter = querySnapshot.docs.filter((evId) => evId.data().status == 'Aprobado');
				if (filter.length >= obi.docs.length) {
					if(_this.state.accesedSerie.data().certificate_issued == true){
						console.log("certificate_issued");
					}else{
						if(_this.state.serie.data().video_section_as_evaluation == false){
							if(_this.state.serie.data().inclue_cases_section_as_evaluation == true){
								console.log("inclue_cases_section_as_evaluation");
								console.log("getCasesInformation...");
								_this.getCasesInformation()
		
							}else{
								if(_this.state.serie.data().cases_section_only_as_evaluation == false){
									
									_this.setState({ certificateWinner: true });
									_this.getCertificateReady();
								}
							}
						}
				}
				
				} else {
					_this.setState({ approved: true });
				}
			
		})
		.catch(function(error) {
			//console.log("Error getting documents: ", error);
		});
	
	}
	checkIfCertificateOrMessage() {
		//console.log('Checking certificate');
		var _this = this;
		database.collection("evaluations").where('series', '==', _this.state.seriesId)
    .get()
    .then(function(querySnapshot) {
		//console.log("GOT ALL DATA")
        _this.getAllCertificateData(querySnapshot)
    })
    .catch(function(error) {
        //console.log("Error getting documents: ", error);
    });

	
	}

	gradeEvaluation = () => {
		var _this = this;

		if (!_this.state.graded) {
			//console.log("RELEVANT")
			//console.log(_this.state.questions);
			//console.log(_this.state.solvedQuestions);
			//console.log(_this.state.solvedQuestionsPath);
			if (_this.state.questions.length > _this.state.uSolvedQuestions.length) {
				_this.setState({ danger: true, loading: true });
				setTimeout(() => {
					this.setState({
						danger: false,
						loading: false
					});
				}, 2000);
			} else {
				database.collection(_this.state.solvedQuestionsPath).get().then(function (snapshot) {
					var hasAllAnswers = true
					var correctAnswers = 0;
					var wrongAnswers = 0;
					_this.state.questions.forEach(function (doc) {
						var answersForQuestion = snapshot.docs.filter(answers => answers.data().question == doc.id)
						if(!answersForQuestion){
							hasAllAnswers = false
						}else{
							if(answersForQuestion.length>1){
								answersForQuestion.sort(function (a, b) {
									return Moment(a.data().timestamp).isAfter(Moment(b.data().timestamp));
								});
							
							}
						
							if (answersForQuestion[0].data().was_correct == 'true') {
								correctAnswers++;
							} else {
								wrongAnswers++;
							}


							
						}
					});
					if(!hasAllAnswers){
						_this.setState({
							gradeMessage:
								'Debes responder todas las preguntas para poder recibir una calificación' 
						});
						_this.setState({ failure: true });
					}else{

						var grade = ((_this.state.questions.length - wrongAnswers) / _this.state.questions.length) * 100;
						grade = Math.floor(grade)
						if (grade > 100) {
							grade = 100
						}
						//console.log('GRADE');
						_this.setState({ grade: grade + "" });
						//console.log(grade);

						if (_this.state.serie.data().evaluation_procentage > grade) {
							database.doc(_this.state.solvedEvPath).update({
								correct_answers: correctAnswers + '',
								wrong_answers: wrongAnswers + '',
								timestamp: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
								grade: grade,
								porcentage: '100',
								evaluation_id: _this.state.evaluation.id,
								status: 'Reprobado',
								evaluation_name: _this.state.evaluation.data().title
							});
							_this.setState({
								gradeMessage:
									'Lo sentimos, no has llegado al número mínimo de aciertos para aprobar la evaluación, Inténtelo de nuevo.\nCalificación: ' + grade + '%'
							});
							_this.setState({ failure: true });

							var tries = Number(_this.state.currentSolvedEvaluation.attempt_number);
							var limit = Number(_this.state.serie.data().number_of_tries);
							//console.log('CURRENT TRY' + _this.state.currentSolvedEvaluation.attempt_number);
							//console.log('LIMIT:' + _this.state.serie.data().number_of_tries);
							if (limit > tries) {
								var attempt = Number(tries) + 1;
								//console.log(_this.state.path);
								var finalPAth = _this.state.path;
								database
									.collection(finalPAth + '/solved_evaluations')
									.add({
										correct_answers: '0',
										wrong_answers: '0',
										timestamp: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
										grade: '0',
										evaluation_id: _this.state.evaluation.id,
										porcentage: '0',
										status: 'Por iniciar',
										attempt_number: attempt + '',
										evaluation_name: _this.state.evaluation.data().title
									})
									.then(function () {
										//console.log('NEW ATTEMPT ADDED ' +(_this.state.currentSolvedEvaluation.attempt_number + 1));
									})
									.catch(function (error) {
										//console.log('ERROR INSERTING ATTEMPT : ');
										//console.log(error);
									});
							} else {
								_this.setState({ maxTries: true });
							}
						} else {
							database
								.doc(_this.state.solvedEvPath)
								.update({
									correct_answers: correctAnswers + '',
									wrong_answers: wrongAnswers + '',
									timestamp: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
									grade: grade,
									porcentage: '100',
									evaluation_id: _this.state.evaluation.id,
									status: 'Aprobado',
									evaluation_name: _this.state.evaluation.data().title
								})
								.then(function () {
									_this.checkIfCertificateOrMessage();
								})
								.catch(function (er) {
									//console.log('ERROR INSERTING ATTEMPT : ');
									//console.log(er);
								});
						}

						if (_this) _this.setState({ disabledButtons: true, gradedButton: 'Regresar', graded: true });
					}

				});
				
					
						
						
					
			
			}
		} else {
			_this.setState({ redirectHome: true });
		}
	};

	getCertificateReady() {
		var _this = this;

		var addMessage = functions.httpsCallable('createCertificate');
		var n = new Date().getTime();
		var folio = (n + '').substr((n + '').length - 5);
		Moment().locale('es', localization)

		var dateMessage = 'Ciudad de México, ' + Moment(new Date()).locale('es').format('D [de] MMMM [del]  YYYY');
		addMessage({
			seriesId: _this.state.serie.id,
			userId: _this.state.user.id,
			folio: folio,
			date: dateMessage,
			formalDate: Moment(n).format('DD/MM/YYYY HH:mm:ss'),
			accessed_series: 'users/' + _this.state.user.id + '/accesed_series/' + _this.state.accesedSerie.id
		})
			.then(function(result) {
				// Read result of the Cloud Function.
				//var sanitizedMessage = result.data.text;
			})
			.catch(function(error) {
				// Getting the Error details.
				var code = error.code;
				var message = error.message;
				var details = error.details;
				// ...
			});
	}

	getQuestionOptions(question, index) {
		var _this = this;
		database.collection('options').where('question', '==', question.id).get().then(function(querySnapshot) {
			//console.log('OPTIONS');
			//console.log(querySnapshot);
			if (querySnapshot.docs.length > 0) {
		var sorted = querySnapshot.docs.sort(function(a, b) {
					if(a.data().order < b.data().order) { return -1; }
					if(a.data().order > b.data().order) { return 1; }
					return 0;
					});
				question.options = sorted;
				question.visible = true;
				_this.setState({
					currentOptions: sorted,
					currentTitle: question.title
				});
				_this.showModal();
			}
		});
	}

	getQuestionsInformation() {
		var _this = this;
		database
			.collection('questions')
			.where('evaluation', '==', _this.state.evId)
			.get()
			.then(function(querySnapshot) {
			
				var sorted = querySnapshot.docs.sort(function(a, b) {
					if(Number(a.data().order) < Number(b.data().order)) { return -1; }
					if(Number(a.data().order) > Number(b.data().order)) { return 1; }
					return 0;
					});
		
				_this.setState({ questions: sorted});
			});
	}

	getEvaluationInformation(id) {
		var _this = this;
		//console.log(_this.state.evId)
		database.doc('evaluations/' + _this.state.evId).get().then(function(doc) {
			if (doc.exists) {
				if(doc.data().series == id){
					_this.setState({ evaluation: doc });
					_this.getQuestionsInformation();
					_this.getSolvedSeriesInformation();
				}else{
					//console.log('EVALUATION DOES NOT EXIST');
				}
			} else {
				//console.log('EVALUATION DOES NOT EXIST');
			}
		});
	}

	getSeriesInformation() {
		var _this = this;
		database.doc('series/' + _this.state.seriesId).get().then(function(doc) {
			if (doc.exists) {
				_this.setState({ serie: doc });
				_this.getEvaluationInformation(doc.id);

			} else {
				//console.log('SERIES DOES NOT EXIST');
			}
		});
	}

	
	getSolvedSeriesInformation() {
		var _this = this;
		var path =
			'/users/' + _this.state.user.id + '/accesed_series/' + _this.state.accesedSerie.id + '/solved_evaluations';
		var ref = database.collection(path);

		ref.where('evaluation_id', '==', this.state.evId).get().then(function(snapshot) {
			if (snapshot.docs.length == 0) {
				_this.writeNewAttempt(path, 1);
			} else {
				var approved = snapshot.docs.filter((word) => word.data().status == 'Aprobado');
				var failed = snapshot.docs.filter((word) => word.data().status == 'Reprobado');
				var limit = Number(_this.state.serie.data().number_of_tries);
				//console.log(approved);
				//console.log(failed);
				//console.log(limit);
				//console.log(failed.length);
				if (approved.length == 0) {
					if (failed.length < limit) {
						var filter = snapshot.docs.filter(
							(word) => word.data().status == 'Por iniciar' || word.data().status == 'En curso'
						);
						if (filter.length > 0) {
							//console.log('SOLVED EVALUATIONS');
							//console.log(filter);

							//console.log('SORTED SOLVED EVALUATIONS');
							//console.log(filter);
							var solvedEv = filter[0];
							//console.log('CHOSEN ATTEMOR');
							var partialPath = path + '/' + solvedEv.id;
							var completePath = partialPath + '/solved_questions';
							//console.log('PATH');
							//console.log(completePath);
							//console.log(solvedEv);
							_this.setState({
								path:
									'/users/' + _this.state.user.id + '/accesed_series/' + _this.state.accesedSerie.id,
								currentSolvedEvaluation: solvedEv.data(),
								solvedQuestionsPath: completePath,
								solvedEvPath: partialPath
							});
							database.collection(completePath).get().then(function(solvedQuestions) {
								if (solvedQuestions.docs.length != 0) {
									//console.log('SOLVED QUESTIONS');
									//console.log(solvedQuestions.docs);
									//console.log('SOLVED QUESTIONS');
									//console.log(solvedQuestions.docs[0].data());
									_this.setState({
										solvedQuestions: solvedQuestions.docs,
										uSolvedQuestions: solvedQuestions.docs,

										solvedQuestionsLoaded: true
									});
								} else {
									_this.setState({
										solvedQuestions: [],
										uSolvedQuestions: [],
										solvedQuestionsLoaded: true
									});
								}
							});
						} else {
							_this.writeNewAttempt(path, failed.length + 1);
						}
					} else {
						//console.log('Reprobado');
						_this.setState({ maxTries: true });
					}
				} else {
					//console.log('PASADO');

					_this.setState({ approved: true });
				}
			}
		});
	}

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	writeNewAttempt(path, attempt) {
		//console.log('NO ATTEMP, WRITING NEW ONE');
		var _this = this;

		database
			.collection(path)
			.add({
				correct_answers: '0',
				wrong_answers: '0',
				status: 'Por iniciar',
				evaluation_name: _this.state.evaluation.data().title,
				attempt_number: attempt + '',
				evaluation_id: this.state.evId,
				grade: '0',
				porcentage: '0',
				timestamp: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
			})
			.then(function(doc) {
				var path =
					'/users/' +
					_this.state.user.id +
					'/accesed_series/' +
					_this.state.accesedSerie.id +
					'/solved_evaluations';

				database.doc(path + '/' + doc.id).get().then(function(item) {
					//console.log('Document successfully written!');
					//console.log(item);

					var partialPath = path + '/' + item.id;
					var completePath = partialPath + '/solved_questions';
					//console.log(path);
					_this.setState({
						currentSolvedEvaluation: item.data(),
						path: path,
						solvedQuestions: [],
						solvedQuestionsLoaded: true,
						uSolvedQuestions: [],
						solvedEvPath: partialPath,
						solvedQuestionsPath: completePath,
						solvedEvPath: partialPath
					});
				});
			})
			.catch(function(error) {
				//console.error('Error writing document: ', error);
			});
	}

	getUserAccessibleSeries(userSnapshot) {
		var _this = this;
		var doc = userSnapshot.docs[0];
		_this.setState({ user: doc });
		database
			.collection('/users/' + doc.id + '/accesed_series')
			.where('series_id', '==', _this.state.seriesId)
			.get()
			.then(function(accesedSeries) {
				if (accesedSeries.docs.length == 0) {
					_this.setState({ denied: true });
				} else {
					_this.setState({ accesedSerie: accesedSeries.docs[0] });
					_this.getSeriesInformation();
				}
			});
	}

	getUserInformation(user) {
		var _this = this;
		database
			.collection('users')
			.where('email', '==', user.email)
			.get()
			.then(function(querySnapshot) {
				_this.getUserAccessibleSeries(querySnapshot);
			})
			.catch(function(error) {
				//console.log('Error gettin0g document:', error);
			});
	}

	componentDidMount() {
		const { seriesId, evId } = this.props.match.params;
		//console.log(this.props.match.params)
		var _this = this;
		this.setState({ seriesId: seriesId, evId: evId });
		auth.onAuthStateChanged((user) => {
			if (user) {
				_this.getUserInformation(user);
			} else {
				this.setRedirect();
			}
		});
	}

	success() {
		Modal.success({
			title: 'This is a success message',
			content: 'some messages...some messages...'
		});
	}

	render() {
		const radioStyle = {
			display: 'block',
			height: '30px',
			lineHeight: '30px'
		};
		return (
			<div>
				<Button type="primary" href ={'/series/' + this.state.seriesId+'/evaluations'} > <Icon type="left" size="small" />Regresar</Button>
				<Button type="dashed">Su avance es guardado de forma automática. Puede salir del sitio y regresar en otro momento para concluir sus evaluaciones</Button>

				<Card title="Preguntas" className="gx-card">
				
				<Row>
						{this.state.solvedQuestionsLoaded &&
						this.state.questions.length > 0 &&
						this.state.solvedQuestionsPath != '' ? (
							this.state.questions.map((question, index) => (
			
								<Col key={index} lg={4} md={6} sm={9} xs={9}>
									<Question
										disabledButtons={this.state.disabledButtons}
										key={index}
										danger={this.state.danger}
										onAddAnswer={this.onAddAnswer}
										onUpdateAnswer={this.onUpdateAnswer}
										user={this.state.user}
										question={question}
										index={index}
										path={this.state.solvedQuestionsPath}
										solvedQuestion={this.state.solvedQuestions.find(function(item) {
											//console.log('COMPARING');
											return item.data().question == question.id;
										})}
									/>
								</Col>
					
							))
						) : null}
				</Row>
		
				</Card>
				<Button
					onClick={this.gradeEvaluation}
					type="primary"
					size="large"
					icon="highlight"
					loading={this.state.loading}
				>
					{this.state.gradedButton}
				</Button>
				<SweetAlert
					show={this.state.success}
					success
					title={this.state.gradeMessage}
					onConfirm={() => {
						this.setState({ success: false });
					}}
				/>
				<SweetAlert
					show={this.state.failure}
					error
					title={this.state.gradeMessage}
					onConfirm={() => {
						this.setState({ failure: false });
					}}
				/>
				<SweetAlert
					show={this.state.maxTries}
					error
					title="Lo sentimos, has llegado al máximo número de intentos para esta evaluación"
					onConfirm={() => {
						this.setState({ maxTries: false });

						this.setState({ redirectHome: true });
					}}
				/>
				<SweetAlert
					show={this.state.denied}
					error
					title="No tiene acceso al contenido de esta serie, por favor solicite un código de acceso"
					onConfirm={() => {
						this.setState({ denied: false });

						this.setState({ redirectHome: true });
					}}
				/>
				<SweetAlert
					show={this.state.certificateWinner}
					success
					title="Felicidades, ha aprobado. Su constancia será enviada a su correo electrónico automáticamente."
					onConfirm={() => {
						this.setState({ certificateWinner: false });
					}}
				/>
				<SweetAlert
					show={this.state.approved}
					success
					title={"Has aprobado esta evaluación.\nCalificación:"+this.state.grade+'%'}
					onConfirm={() => {
						this.setState({ approved: false });

						this.setState({ redirectHome: true });
					}}
				/>

				
				{this.renderRedirect()}
				{this.renderRedirectHome()}
			</div>
		);
	}
}
export default Evaluation;

/*
const Icon = () => {
  return (<Card className="gx-card" title="Icon">
      <Button type="primary" size="large" icon="search"/>
      <Button type="primary" size="large" icon="search">Search</Button>
      <Button shape="circle" icon="search"/>
      <Button icon="search">Search</Button>
      <br/>
      <Button shape="circle" icon="search"/>
      <Button icon="search">Search</Button>
      <Button type="dashed" shape="circle" icon="search"/>
      <Button type="dashed" icon="search">Search</Button>
    </Card>
  );
};*/
