import React, { Component } from 'react';
import { Col, Row, Card, Button, Icon, Progress } from 'antd';
import CaseItem from "components/eCommerce/CaseItem";
import { auth, database, functions } from "../../../firebase/firebase";
import About from '../../../components/profile/About';
import AboutItem from '../../../components/profile/About/AboutItem';
import Moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import localization from 'moment/locale/es'

class CasesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      urlId: 0,
      series: {},
      cases: []
    };
    //this.handleChange=this.handleChange.bind(this);
  }

  getEvaluationsForChecking() {
    var _this = this;
    database.collection("evaluations").where('series', '==', _this.state.urlId)
      .get()
      .then(function (querySnapshot) {
        _this.verifyEvaluationCount(querySnapshot)
      })
      .catch(function (error) {
      });
  }

  verifyEvaluationCount(evaluations) {
    var _this = this;

    database.collection('users/' + _this.state.userId + '/accesed_series/' + _this.state.accesedSerieId + '/solved_evaluations')
      .get()
      .then(function (querySnapshot) {
        var filter = querySnapshot.docs.filter((evId) => evId.data().status == 'Aprobado');
        if (filter.length >= evaluations.docs.length) {
          _this.setState({ certificateWinner: true });
          _this.registerFinalReadCasesSummary();
        } else {
          _this.setState({ approved: true });
        }

      })
      .catch(function (error) {
        //console.log("Error getting documents: ", error);
      });

  }


  getCasesInformation() {
    var _this = this;
    var query = database
      .collection("clinic_cases")
      .where("series", "==", _this.state.urlId)
      query = query.where("active", "==", true);
      query.get()
      .then(function (querySnapshot) {
        if (querySnapshot.docs.length != 0) {
          //Done this way to know how many cases are in
          const result = querySnapshot.docs.filter(word => word.id == _this.state.caseId);

          if (result != undefined) {
            console.log("LOADED")
            console.log("total cases " + querySnapshot.length)
            _this.setState({ case: result[0].data(), totalCases: querySnapshot.docs.length });
            _this.checkIfRead()
          }
        }
      });
  }

  getSeriesData() {
    var _this = this;

    console.log("Getting series info...")
    console.log("series/" + this.state.urlId)
    database.doc("series/" + this.state.urlId).get().then(function (doc) {
      if (doc.exists) {
        _this.setState({ series: doc.data() })
        console.log(doc.data())
        _this.checkIfCertificate()
      }
    })
  }
  checkIfCertificate() {
    if (this.state.certificate_issued == true) {
      console.log("CERTIFICATE ISSUED")
    } else {
      console.log("CHECKING CERTIFICATE...");
      var _this = this;
      if (this.state.series.title == undefined) {
        this.getSeriesData();
      } else {
        console.log("CHEKING...")
        if (this.state.series.video_section_as_evaluation == true) {
          if (this.state.series.cases_section_only_as_evaluation == false) {
            console.log("cases_section_only_as_evaluation...")
            if (this.state.porcentage > this.state.series.read_cases_porcentage_certification) {
              _this.registerFinalReadCasesSummary();
            }

          } else {

            if (this.state.series.inclue_cases_section_as_evaluation == false) {
              console.log("inclue_cases_section_as_evaluation...")

              if (this.state.porcentage > this.state.series.read_cases_porcentage_certification) {
                _this.getEvaluationsForChecking();
              }


            } else {
              console.log("ONLY REGULAR EVALUATION")

            }
          }
        } else {
          console.log("ONLY VIDEO EVALUATION")
        }
      }
    }
  }
  registerFinalReadCasesSummary() {
    console.log("REGISTERDING SOLVED")

    var _this = this;
    var path = "users/" + this.state.userId + "/accesed_series/" + this.state.accesedSerieId
    database
      .collection(path + '/read_cases_summary')
      .add({
        read_num_of_cases: this.state.readCases,
        timestamp: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
        case_id: _this.state.caseId,
        porcentage: _this.state.porcentage,
        required_porcentage: _this.state.series.read_cases_porcentage_certification,
        status: 'Completado',

        case_name: _this.state.case.title
      })
      .then(function () {
        _this.getCertificateReady();
      })
      .catch(function (error) {
        //console.log('ERROR INSERTING ATTEMPT : ');
        //console.log(error);
      });
  }

  getCertificateReady() {
    console.log("GETTING CERTIFICATE READY")
    var _this = this;

    var addMessage = functions.httpsCallable('createCertificate');
    var n = new Date().getTime();
    var folio = (n + '').substr((n + '').length - 5);
    Moment().locale('es', localization)

    var dateMessage = 'Ciudad de México, ' + Moment(new Date()).locale('es').format('D [de] MMMM [del]  YYYY');
    var contents = {
      seriesId: _this.state.urlId,
      userId: _this.state.userId,
      folio: folio,
      date: dateMessage,
      formalDate: Moment(n).format('DD/MM/YYYY HH:mm:ss'),
      accessed_series: 'users/' + _this.state.userId + '/accesed_series/' + _this.state.accesedSerieId
    }
    console.log("CONTENTS")
    console.log(contents)
    addMessage(contents)
      .then(function (result) {
        console.log("SHOWING CONGRATS")
        console.log(result)
        _this.showWinnerMessage();
      })
      .catch(function (error) {
        var code = error.code;
        var message = error.message;
        var details = error.details;
        console.log("ERROR")
        console.log(code + " " + message + " " + details)


      });
  }

  showWinnerMessage() {
    var mes = "Felicidades, ha aprobado. Su constancia será enviada a su correo electrónico automáticamente."

    this.setState({ certificateWinnerMessage: mes, certificateWinner: true })
  }

  calculatePorcentage() {
    var _this = this;
    var path = "users/" + _this.state.userId + '/read_cases';
    console.log("Checking if read");
    console.log(path);
    database
      .collection(path).where('series_id', '==', _this.state.urlId)
      .get()
      .then(function (querySnapshot) {
        //console.log('OPTIONS'); //console.log(querySnapshot);
        if (querySnapshot.docs.length != 0) {

          var por = parseInt((querySnapshot.docs.length / _this.state.totalCases) * 100)
          _this.setState({ porcentage: por, readCases: querySnapshot.docs.length, loaded: true });
          setTimeout(() => {
            _this.checkIfCertificate()
          }, 200)



        } else {
          _this.setState({ porcentage: 0, readCases: 0, loaded: true });
          console.log("0% reare case")
        }
      }).catch(function (error) {
        _this.setState({ porcentage: 0, readCases: 0, loaded: true });
      });
  }


  checkIfRead() {
    var _this = this;
    var path = "users/" + _this.state.userId + '/read_cases';
    console.log("Checking if read");
    console.log(path);
    database
      .collection(path).where('case_id', '==', _this.state.caseId)
      .get()
      .then(function (querySnapshot) {

        if (querySnapshot.docs.length == 0) {

          _this.markAsRead()
        } else {
          _this.calculatePorcentage()
          console.log("Already read")
        }
      });
  }

  markAsRead() {
    var _this = this;
    database
      .collection("users/" + _this.state.userId + '/read_cases')
      .add({
        timestamp: Moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
        case_id: _this.state.caseId,
        series_id: _this.state.urlId,
        status: 'Completado',
        case_name: _this.state.case.title
      }).then(function (doc) {
        _this.calculatePorcentage()

      })

  }

  checkIfUserHasAccessedSeries(doc) {
    var _this = this;
    doc.ref
      .collection("accesed_series")
      .where("series_id", "==", _this.state.urlId)
      .get()
      .then(function (snapshot) {
       
        if (snapshot.docs.length > 0) {
          var serie = snapshot.docs[0]
          _this.setState({ accesedSerie: serie.data(), accesedSerieId: serie.id })
          if (serie.data().certificate_issued == true) {
            _this.setState({ certificate_issued: true });

          }
          _this.getCasesInformation();
        }
      });
  }
  checkifValidUser(user) {

    var _this = this;
    database
      .collection("users")
      .where("email", "==", user.email)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          _this.setState({ user: doc.data(), userId: doc.id });
          _this.checkIfUserHasAccessedSeries(doc);
        });
      })
      .catch(function (error) {
      });
  }

  componentDidMount() {
    const { urlId, caseId } = this.props.match.params;
    var _this = this;

    this.setState({ urlId: urlId, caseId: caseId, loaded: false });
    auth.onAuthStateChanged(user => {
      if (user) {
        _this.checkifValidUser(user);
      }
    });
  }

  render() {
    return (
      <div>

        <SweetAlert
          show={this.state.certificateWinner == true}
          success="success"
          title={this.state.certificateWinnerMessage}
          onConfirm={() => {
            this.setState({ certificateWinner: false });
          }} />
        <Row>
          <Col key="1a" span={4}>
            <Card className={`gx-card-metrics`}>
              <img src={"https://firebasestorage.googleapis.com/v0/b/casosilaprazolchinoin.appspot.com/o/logo-left.png?alt=media&token=41843bdd-239a-4291-ae47-6837d00e8ba1"} />
            </Card>
            <About user={this.state.user} />
            (this.state.loaded)?<Card className={`gx-card-metrics`}>
              <h3>Avance</h3>
              <h4>Usted ha visualizado {this.state.readCases} casos clínicos de un total de {this.totalCases}</h4>
              <Progress percent={this.state.porcentage} size="small" status="active" strokeColor="#EF55A8" />
              <span>Porcentage de avance</span>

            </Card>:null
          </Col>
          {(this.state.loaded) ?
            <Col key="aa" span={20}>
              <Card className={`gx-card-metrics`}>
                <Button type="primary" href={"/series/" + this.state.urlId + "/cases"}>
                  <Icon type="left" size="small" />Regresar</Button>
                <h1 className="gx-product-title">{(this.state.case.title) ? this.state.case.title : ""}</h1>
                <h4>{(this.state.case.title) ? this.state.case.subtitle : ""}</h4>
                <br></br>
                <hr id="hr"></hr>
                <br></br>
                <div id="contenido-html" dangerouslySetInnerHTML={{ __html: (this.state.case.content) ? this.state.case.content : "" }} />

              </Card>
            </Col> : null}
        </Row>
      </div>
    );
  }
}

export default CasesView;
