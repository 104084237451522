import firebase from "firebase";

// Initialize Firebase
const config = {
  databaseURL: "https://casosilaprazolchinoin.firebaseio.com",
  apiKey: "AIzaSyATxGwFTIT8OHRsrtP4KMuh6SKlUQUpyw8",
  authDomain: "casosilaprazolchinoin.firebaseapp.com",
  projectId: "casosilaprazolchinoin",
  storageBucket: "casosilaprazolchinoin.appspot.com",
  messagingSenderId: "1053273086242",
  appId: "1:1053273086242:web:c688781db406e2de246d7b",
  measurementId: "G-G1JG83V9J4"
};
firebase.initializeApp(config);
firebase.auth().languageCode = 'es'
const auth = firebase.auth();
const functions = firebase.functions();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const storage = firebase.storage();

const database = firebase.firestore();

export {
  database,
  storage,
  auth,
  functions,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
